window.embededVideosHandler = function () {
  const player = $('.jsVideo').get(0);
  let volumeVal = null;

  $(document).ready(function () {

    // Show video panel control
    $('.jsVideo').hover(function () {
      $(this).siblings('.jsVideoControl').css('opacity', '1');
    }, function () {
      $(this).siblings('.jsVideoControl').css('opacity', '0');
    });
    $('.jsVideoControl').hover(function () {
      $(this).css('opacity', '1');
    }, function () {
      $(this).css('opacity', '0');
    });
  });

  // volume button
  $(".jsVideoVolume").on("change input", function () {
    const val = parseInt($(this).val());
    volumeVal = (val / 100);
    player.volume = volumeVal;

    if (val === 0) {
      player.muted = true;
      $(this).siblings(".jsVideoSound").addClass("disabled");
    } else {
      player.muted = false;
      $(this).siblings(".jsVideoSound").removeClass("disabled");
    }
  });

  // replay button
  $(".jsVideoReplay").on("click", function () {
    player.load();
    $(".jsVideoPlay").trigger('click')
  });

  // play/pause button
  $(".jsVideoPlay").on("click", function (e) {
    const videoBorder = $(this).parents('.jsVideoContainer').find('.jsVideo');
    $(this).toggleClass("disabled");
    if ($(this).hasClass("disabled")) {
      player.play();
      videoBorder.css('border-color', 'transparent');
    } else {
      player.pause();
      videoBorder.css('border-color', '#DB3645');
    }
  });

  // sound button
  $(".jsVideoSound").on("click", function () {
    $(this).toggleClass("disabled");

    if ($(this).hasClass("disabled")) {
      player.volume = 0;
      player.muted = true;
      volumeVal = null;
      $(".jsVideoVolume").val(0);
    } else {
      if (volumeVal === null) {
        volumeVal = 100;
        player.muted = false;
      }

      // volume is between 0 - 1
      player.volume = (volumeVal / 100);

      $(".jsVideoVolume").val(volumeVal);
    }
  });

  // full screen button
  $('.jsVideoFullScreen').on('click', () => {
    const video = $('.jsVideo')[0];

    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.webkitRequestFullscreen) { /* Safari */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE11 */
      video.msRequestFullscreen();
    } else if (video.mozRequestFullScreen) { /* FF */
      video.mozRequestFullScreen();
    }
  });


  // Mobile version
  const seeMoreBtn = $('.jsMobilePlayVideo');
  const videoControl = $('.jsVideoControl');
  const videoControlPlay = videoControl.find('.jsVideoPlay');

  $(seeMoreBtn).on('click', function () {
    $(this).css('opacity', '0');
    videoControl.css('opacity', '1');
    $(this).parents('.jsVideoContainer').find('.jsVideoPlay').trigger('click');
  });
};
